














































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetOrgaos,
  dispatchUpdateOrgao,
} from '@/store/orgao/actions';
import { consultarOrgao } from '@/store/orgao/getters';
import { IOrgaoUpdate } from '@/interfaces/orgao';

@Component
export default class AlterarOrgao extends Vue {
  public valid = true;
  public nome: string = '';
  public sigla: string = '';

  public async mounted() {
    await dispatchGetOrgaos(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.orgao) {
      this.nome = this.orgao.nome;
      this.sigla = this.orgao.sigla;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const orgaoAtualizado: IOrgaoUpdate = {
        nome: this.nome,
        sigla: this.sigla,
      };

      const atualizou = await dispatchUpdateOrgao(this.$store, {
        id: this.orgao!.id,
        orgao: orgaoAtualizado,
      });
      if (atualizou) {
        this.$router.push('/main/orgaos/listar');
      }
    }
  }

  get orgao() {
    return consultarOrgao(this.$store)(+this.$router.currentRoute.params.id);
  }
}
